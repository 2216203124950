import React, { createRef } from 'react';
import { observer, inject } from 'mobx-react';
import style from './style.module.css';
import Modal from 'react-modal';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

@inject(store => {
    const { myShopApiStore, ifaceMngr } = store;
    return { myShopApiStore, ifaceMngr };
})

@observer
class CardNumberModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            inputRef: createRef(null),
            cardNumber: '',
            error: ''
        }

        this.modalStyle = {
            content : {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                borderRadius: '20px',
                width: 'max-content',
                height: '40%',
            },
            overlay: {
                backgroundColor: "rgb(0, 0, 0, 0.65)",
                zIndex: 10
            }
        }
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        const { setCardNumber } = this.props.myShopApiStore;

        if(this.state.cardNumber === ''){
            this.setState({error: 'שדה ריק!'})
        }
        else{
            this.setState({error: ''})
            setCardNumber(this.state.cardNumber);
            await this.props.handleSendClick();
            this.props.closeModal();
        }
    }

    handleCloseModal = () => {
        this.props.myShopApiStore.setCardNumber('')
        this.props.myShopApiStore.setOtherPaymentType('')
        this.props.closeModal();
    }

    handleChange = (e) => {
        this.setState({cardNumber: e.target.value})
        e.target.value && this.setState({error: ''})
    }

    componentDidMount = () => {
        this.props.myShopApiStore.setCardNumber('')
        this.props.myShopApiStore.setOtherPaymentType('')
    }
    

    render() {
        const { modalIsOpen } = this.props;
        const { mainColor } = this.props.myShopApiStore;
        return (
            <Modal style={this.modalStyle} isOpen={modalIsOpen} onRequestClose={this.handleCloseModal}>
                <div className={style.ModalContainer} id="my-coupon">
                    <form onSubmit={this.handleSubmit}>
                        <div className={style.CloseBtn} onClick={this.handleCloseModal}>✕</div>
                            <div className={style.BodyContent}>
                                <label>הקלד מספר כרטיס:</label>
                                <input type='text' ref={this.inputRef}
                                       onChange={this.handleChange}
                                       style={{border: `1px solid ${this.state.error ? '#FF0000' : '#808080'}`}}/>
                            </div>
                        <div className={style.SendButton}>
                            <button style={{color: mainColor, border: `2px solid ${mainColor}`}} onClick={() => {}}>שלח</button>
                        </div>
                    </form>
                </div>
            </Modal>
        )
    }
}

export default CardNumberModal;