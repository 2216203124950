import React from "react";
import { observer, inject } from "mobx-react";
import style from "./style.module.css";
import CouponModal from "./CouponModal";
import CouponSuccessModal from "./CouponSuccessModal";
import CloseStoreModal from "./CloseStoreModal";
import ProductsAssociatedModal from 'Components/MainScreen/AppBody/ProductsAssociatedModal';

@inject((store) => {
  const { myShopApiStore, ifaceMngr } = store;
  return { myShopApiStore, ifaceMngr };
})

@observer
class PaymentButtons extends React.Component {
    constructor(props) {
        super(props)
    
        // this.hasCoupon = this.props.myShopApiStore.getCoupon()
        this.state = {
             isCouponModalOpen: false,
             closeStoreModalIsOpen: false,
             successModalIsOpen: false, //coupon success activation
             productsAssociatedModalIsOpen: false,
             productsAssociated: null,
             unitTypeSelected: null,
         }
    }

    openProductsAssociatedModal = async () => {
        const {setAssociatedStage} = this.props.myShopApiStore;
        setAssociatedStage(true);
    }
    closeProductsAssociatedModal = () => {
        const { resetOrderData, setAssociatedStage } = this.props.myShopApiStore;
        setAssociatedStage(false);
        resetOrderData(); //clean order data details
        this.openPaymentPage();

    }
    
    openSuccessModal = () => { //for coupon success activation
        this.setState({successModalIsOpen: true})
    }

    closeSuccessModal = () => { //for coupon success activation
        this.setState({successModalIsOpen: false})
    }

    openCloseStoreModal = () => { 
        this.setState({closeStoreModalIsOpen: true})
    }

    closeCloseStoreModal = () => { 
        this.setState({closeStoreModalIsOpen: false})
    }

    openCouponModal = () => {
        this.setState({isCouponModalOpen: true})
    }

    closeCouponModal = (isSuccess) => {
        this.setState({isCouponModalOpen: false})

        if(isSuccess){
            this.openSuccessModal();
        }
    }

    openPaymentPage = () => {
        this.setState({inAssociatedProducts: false});
        const { setActiveTopMenu } = this.props.ifaceMngr;
        setActiveTopMenu("Payment")
    }

    handleGoToPaymentClick = async () => {
        const { resetOrderData, shop, setSearchValue, checkAssociatedProductsInCart ,getProductsAssociated } = this.props.myShopApiStore;
        const { orderTypes } = shop;

        const hasDeliveryOp = orderTypes ? orderTypes.some(ot => ot === 'delivery') : true;
        const hasPickupOp = orderTypes ? orderTypes.some(ot => ot === 'pickup') : true;

        setSearchValue('', true);

        //  create a variable to hold the associated products
        let shopProductsAssociatedIds = checkAssociatedProductsInCart();

        if (shopProductsAssociatedIds.length) {
            await getProductsAssociated();
            //  open the modal
            this.openProductsAssociatedModal();
        }else {
            if(!hasDeliveryOp && !hasPickupOp){
                this.openCloseStoreModal();
                return;
            }
            resetOrderData(); //clean order data details
            this.openPaymentPage();
        }
    }

    setUnitTypeSelected = (unitType) => {
        this.setState({unitTypeSelected: unitType})
    }

    //  handle Add associated products
    handleAddAssociated = async (item = null) => {
        const {addToCart, isPackItemUpdating,
            isCartItemUpdating} = this.props.myShopApiStore;
        
        let isDuringOrder = isPackItemUpdating || isCartItemUpdating;
        if(!isDuringOrder){
            addToCart(item, item.unitTypes[0]);
        }
    }
    
    render() {
        const { getCoupon, mainColor, shop, inAssociatedStage, associatedProducts} = this.props.myShopApiStore;
        const hasCoupon = getCoupon();
        return (
            <div className={style.Container}>
                <CouponSuccessModal closeModal={this.closeSuccessModal} modalIsOpen={this.state.successModalIsOpen}/>
                <CloseStoreModal closeModal={this.closeCloseStoreModal} modalIsOpen={this.state.closeStoreModalIsOpen}/>
                <ProductsAssociatedModal modalIsOpen={inAssociatedStage}
                        closeModal={this.closeProductsAssociatedModal}
                        itemList={associatedProducts}
                        handleAddClick={this.handleAddAssociated}
                    />

                { this.state.isCouponModalOpen && <CouponModal closeModal={this.closeCouponModal}/> }
                <div className={style.PaymentBtns}>
                {
                    shop.isBusiness
                    ?
                        <div style={{height: "25px", cursor: 'unset'}}/>
                    :
                        <div onClick={(this.state.isCouponModalOpen || hasCoupon) ? null : this.openCouponModal} disabled={this.state.isCouponModalOpen || hasCoupon} style={{backgroundColor: hasCoupon && "rgb(217, 217, 217)", border: `1px solid ${mainColor}`, color: mainColor}}>יש לך קוד קופון?&nbsp;<span>לחץ כאן</span></div>
                }
                   <div onClick={this.handleGoToPaymentClick} disabled={this.state.isCouponModalOpen} style={{ backgroundColor: mainColor }}>מעבר לתשלום</div>
                   <div disabled={this.state.isCouponModalOpen} style={{ margin: 0}}>דמי משלוח יתעדכנו לאחר הזנת הכתובת</div>
                </div>
            </div>
        )
    }
}

export default PaymentButtons
