import React from 'react';
import { observer, inject } from 'mobx-react';
// import ReactDOM from 'react-dom';
import style from './style.module.css';
import Modal from 'react-modal';
import welcome_bg from '../../../assets/welcome_bg.png'
import InputFields from 'Components/MainScreen/AppBody/ValidSMSModal/InputFields';
import APILoader from 'Components/MainScreen/AppBody/APILoader';
@inject(store => {
    const { myShopApiStore, ifaceMngr } = store;
    return { myShopApiStore, ifaceMngr };
})

@observer
class LoginBusinessStoreModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            code1: "", 
            code2: "", 
            code3: "", 
            code4: "",
            isMobile: window.innerWidth <= 950,
            error: '',
            loading: false,
        }

        this.modalStyle = {
            content : {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                borderRadius: '20px',
                margin: 0,
                padding: 0,
                // background: `url(${welcome_bg}) no-repeat`,
                // backgroundSize: '100%',
                // backgroundPosition: 'top',
                backgroundColor: '#FFFF',
                // maxHeight: '100%',

                // width: '450px',
                width: 'max-content',
                height: 'max-content',
                // height: '340px',
                // height: '263px',
                
            },
            overlay: {
                backgroundColor: "rgb(0, 0, 0, 0.65)",
                zIndex: 10
            }
        }
    }


    updateIsMobile = () => {
        this.setState({ isMobile: window.innerWidth <= 850 });
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateIsMobile); 
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateIsMobile);
      }
      

    handleCodeChange = (e) => {
        const { name, value } = e.target;
        this.state.error && this.setState({error: ''})

        if(!value.match(/\D/)){
            this.setState({[name]: value.slice(-1)});
        }

        const code = this.buildCode(name,value);
        
        this.handleApproveClick(code);
    }

    buildCode = (name, value) => {
        if(name === 'code1'){
            return value + this.state.code2 + this.state.code3 + this.state.code4
        }
        if(name === 'code2'){
            return this.state.code1 + value + this.state.code3 + this.state.code4
        }
        if(name === 'code3'){
            return this.state.code1 + this.state.code2 + value + this.state.code4
        }
        if(name === 'code4'){
            return this.state.code1 + this.state.code2 + this.state.code3 + value
        }
    }

    handleCancleClick = () => {
        this.setState({code1: "", code2: "", code3: "", code4: "", error: ''});
        this.props.closeModal();
    }

    handleApproveClick = async (code) => {
        const { openStore, getValidateCode } = this.props.myShopApiStore;
        const { shop } = this.props;
        
        if(code.length < 4){
            this.setState({error: true})
        }
        else{
            this.setState({loading: true})
            const resp = await getValidateCode(shop.id, code)
            this.setState({loading: false})
            
            if(resp?.errorCode === '0'){
                openStore(shop.id, shop.name)
            }
            else{
                this.setState({code1: "", code2: "", code3: "", code4: "", error: resp?.errorMessage || "שגיאת התחברות!"})
            }
        }
    }

    render() {
        const {modalIsOpen } = this.props;
        return (
            <Modal style={this.modalStyle} isOpen={modalIsOpen} onRequestClose={this.handleCancleClick}>
                <APILoader modalIsOpen={this.state.loading} />
                <div className={style.ModalContainer}>
                    <div className={style.closeBtn} onClick={this.handleCancleClick}>✕</div>
                    <h3>
                        אנא הזן קוד
                    </h3>
                    <div className={style.Inputs}>
                        <InputFields isMobile={this.state.isMobile}
                                     handleChange={this.handleCodeChange}
                                     c1={this.state.code1}
                                     c2={this.state.code2}
                                     c3={this.state.code3}
                                     c4={this.state.code4}
                        />
                        <div className={style.errorMsg}>
                            {
                                this.state.error
                            }
                        </div>
                    </div>
                    {/* <div className={style.Btns}>
                        <button onClick={this.handleApproveClick}>אישור</button>
                        <button onClick={this.handleCancleClick}>ביטול</button>
                    </div> */}
                </div>
            </Modal>
        )
    }
}

export default LoginBusinessStoreModal;