import { observable, action } from "mobx";

class IfaceMngr {
    TopMenuItems = ["About", "History", "Terms", "ShopMenu"];
    @observable init = false;
    @observable activeTopMenu = "ShopMenu";
    
    @observable contextMenuVisible = false;

    resetState = () => {
        this.hideContextMenu();
        // this.setActiveTopMenu('About');
    }

    isMenuItemGood = (item) => {
        return true;
    }

    @action
    getActiveTopMenu(){
        return this.activeTopMenu;
    }

    @action
    setActiveTopMenu = (menuSelected) => {
        if(this.isMenuItemGood(menuSelected)){
            this.activeTopMenu = menuSelected;
            // console.log("setActiveTopMenu:", menuSelected);
        } else {
            // console.log("bad menu item:", menuSelected);
        }
    }

    @action
    setInit = () => {
        this.resetState();
        this.init = true;
    }
    @action
    hideContextMenu = () => {
        if(this.contextMenuVisible) setTimeout(() => {
            this.contextMenuVisible = false;
        }, 10);
    }
}
export default IfaceMngr