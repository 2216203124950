import React from "react";
import { observer, inject } from "mobx-react";
import style from "./style.module.css";
import BafaloStore from "./BafaloStore";
import Spinner from '../Spinner';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

@inject((store) => {
  const { myShopApiStore, ifaceMngr } = store;
  return { myShopApiStore, ifaceMngr };
})

@observer
class BafaloStoresScreen extends React.Component {
    constructor(props) {
        super(props)
        // this.scrollUpRef = React.createRef();
        this.state = {
             modalIsOpen: false,
            //  isMobile: window.innerWidth <= 800
        }
    }

    // updateIsMobile = () => {
    //     this.setState({ isMobile: window.innerWidth <= 800 });
    // }

    // componentDidUpdate() {
    //     this.scrollUpRef.current && this.scrollUpRef.current.scrollIntoView({behavior: 'smooth'})
    // }
    
    componentDidMount = async () => {
        const { getShopsResp, setShopId, setShopName, shopsData } = this.props.myShopApiStore;

        setShopId(null);
        setShopName(null);
        await getShopsResp();

        // this.scrollUpRef.current && this.scrollUpRef.current.scrollIntoView({behavior: 'smooth'})

        // window.addEventListener('resize', this.updateIsMobile)
    }

    // componentWillUnmount() {
    //     window.removeEventListener('resize', this.updateIsMobile);
    // }

    renderShop = (shop, idx) => {
        return(
            <div key={idx}>
                <BafaloStore shop={shop}/>
            </div>
        )
    }

    openModal = () => {
        this.setState({modalIsOpen: true})
    }

    closeModal = () => {
        this.setState({modalIsOpen: false})
    }
 

    render() {
        const { shopsData, shopId } = this.props.myShopApiStore;

        if(!shopsData && shopId === 1){
            return null;
        }
        return (
            <div className={style.Container} ref={this.scrollUpRef}>
                <div className={style.TopMain}>
                    <div className={style.Title}>
                        {shopsData ? shopsData.welcomeMessage : ''}
                    </div>
                    <div className={style.Spinner}>
                        <Spinner loading={!shopsData} color="#c3f5ff" loader="BeatLoader"/>
                    </div>
                </div>
                <div className={style.ScreenBody}>
                    {
                        shopsData
                        ?
                            <div className={style.StoreList}>
                                { shopsData.shops.map(this.renderShop) }
                            </div>
                        :
                            ''
                    }
                </div>
            </div>
        )
    }
}

export default BafaloStoresScreen
