import React from 'react';
import { observer, inject } from 'mobx-react';
import style from './style.module.css';

@inject(store => {
    const { myShopApiStore } = store;
    return { myShopApiStore };
})

@observer
class UnitTypes extends React.Component {
    constructor(props) {
        super(props)
    
        this.state = {
             unitSelected: 'kg'
        }
    }
    
    styleUnit = (unitType) => {
        const { mainColor } = this.props.myShopApiStore;
        const isSelected = this.state.unitSelected === unitType.type;
        const styling = {
            backgroundColor: isSelected ? mainColor : "unset",
            color: isSelected ? "#FFFFFF" : "#000000",
        }

        return styling;
    }

    handleClick = (unitType) => {
        const { removeCartItem, getCart, addToCart } = this.props.myShopApiStore;
        const { item } = this.props;
        const hasMult = item.product.shopToppings ? item.product.shopToppings.length === 0 : false
        let index = -1;
        for(let i=0; i<getCart().cartItemList.length ; i++){
            if(getCart().cartItemList[i].item.id === item.id){
                index = i;
                break;
            }
        }

        if(unitType.type !== this.state.unitSelected && index !== -1){
            removeCartItem(index, hasMult);
            addToCart(item, unitType)
        }
        this.props.setUnitTypeSelected(unitType);

        this.setState({unitSelected: unitType.type})
    }

    render() {
        const { unitTypes } = this.props;
        return (
            <div className={style.Container}>
                {
                    unitTypes.map((unitType, unitIdx) => {
                        return(
                            <div className={style.Unit} key={unitIdx} style={this.styleUnit(unitType)} onClick={() => this.handleClick(unitType)}>
                                { unitType.type === 'kg' ? 'ק"ג' : unitType.type === 'unit' ? "יח'" : unitType.type}
                            </div>
                        )
                    })
                }
            </div>
        )
    }
}

export default UnitTypes;
