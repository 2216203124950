import React, { Component } from 'react'
import { observer, inject } from "mobx-react";
import style from "./style.module.css";
import ReactTooltip from 'react-tooltip';

@inject((store) => {
  const { myShopApiStore } = store;
  return { myShopApiStore };
})

@observer
class EditCartPackForm extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             packItemsToOrder: this.getPackItems()
        }
    }
    
    getPackItems = () => {
        const { cartItemUpdating } = this.props.myShopApiStore;
        let packItemsToOrder = [];

        cartItemUpdating.steps.forEach(step => {
            step.packItems.forEach(packItem => {
                if(packItem.isReadyToOrder){
                    packItemsToOrder.push({packItem: packItem, stepNum: step.stepNum});
                }
            })
        })
        return packItemsToOrder;
    }

    closeForm = () => {
        const { closeCartItemForm, closeEditCartPackForm} = this.props.myShopApiStore;
        closeEditCartPackForm();
        closeCartItemForm();
    }

    changeItem = (packItemToOrder) => {
        const { closeEditCartPackForm, setStepNum} = this.props.myShopApiStore;
        const { stepNum } = packItemToOrder;
        setStepNum(stepNum);
        closeEditCartPackForm();
    }
    
    render() {
        const { cartItemUpdating, mainColor } = this.props.myShopApiStore;
        return (
            <div className={style.Container}>
                <div className={style.CloseButton}>
                    <button onClick={this.closeForm}>✕</button>
                </div>
                <div className={style.CartItemName} style={{color: mainColor}}>{cartItemUpdating.item.product.name}</div>
                <div className={style.PackItems}>
                    {
                        this.state.packItemsToOrder.map((packItemToOrder, idx) => {
                            return(
                                <div key={idx} className={style.PackItemToOrder}>
                                    <div className={style.RightElements}>
                                        <div className={style.ItemImage}>
                                            <img
                                                width='70px'
                                                src={packItemToOrder.packItem.item.product ? packItemToOrder.packItem.item.product.image : ""}
                                                alt={packItemToOrder.packItem.item.product ? packItemToOrder.packItem.item.product.description : ""}
                                            />
                                        </div>
                                        <div className={style.ItemDetails}>
                                            <div className={style.ItemName} style={{color: mainColor}}>
                                                {packItemToOrder.packItem.item.product.name}
                                            </div>
                                            <div className={style.ItemQuantity}>
                                                {packItemToOrder.packItem.item.unitTypes[0].type === "kg" ?
                                                    (`${packItemToOrder.packItem.quantity} ק"ג`) :
                                                    (`${packItemToOrder.packItem.quantity} יח'`)}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={style.LeftElements}>
                                        <div className={style.MoreInfoTooltip} style={{color: mainColor}}>
                                            {
                                                packItemToOrder.packItem.item.product.description && 
                                                <>
                                                    <div data-tip={packItemToOrder.packItem.item.product.description} data-for={`moreInfo_${packItemToOrder.packItem.id}`}>מידע נוסף</div>
                                                    <ReactTooltip
                                                        className={style.Tooltip}
                                                        place="right" 
                                                        multiline={true}
                                                        data-html={true}
                                                        insecure={true} 
                                                        id={`moreInfo_${packItemToOrder.packItem.id}`}
                                                        type='light'
                                                        border={true}
                                                        effect='solid'
                                                    />
                                                </>
                                            }
                                        </div>
                                        <div className={style.ChangeItemBtn}>
                                            <button onClick={() => this.changeItem(packItemToOrder)}
                                                    style={{color: mainColor, border: `1px solid ${mainColor}`}}>
                                            החלף מוצר
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                {/* <button onClick={this.closeForm}>close</button> */}
            </div>
        )
    }
}

export default EditCartPackForm
