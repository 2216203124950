import React from 'react';
import { observer, inject } from 'mobx-react';
// import ReactDOM from 'react-dom';
import style from './style.module.css';
import MenuItem from './MenuItem';

@inject(store => {
    const { myShopApiStore } = store;
    return { myShopApiStore };
})

@observer
class SideMenu extends React.Component {
    render() {
        const { categories, mainColor, shop } = this.props.myShopApiStore;

        if(categories && categories.length === 1){
            return null;
        }

        return (
            <div className={style.Container} style={{backgroundColor: mainColor}}>
                <div className={style.TopDiv}>
                    {
                        categories ? 
                        categories.map((item, index) => {
                            this.props.myShopApiStore.setMaxSideMenuIdx(index);
                            return (
                                <MenuItem item={item} index={index} key={`p_${index}`}/>
                            )
                        }) : ''
                    }
                </div>
            </div>
        )
    }
}

export default SideMenu;