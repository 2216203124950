import React from "react";
import { observer, inject } from "mobx-react";
import style from "./style.module.css";
import FilterModal from '../StoresScreen/FilterModal';
import FastStore from "./FastStore";
import search from '../../assets/search.png';
import search1 from '../../assets/search1.png';
import fast_search from '../../assets/fast_search.svg';
import filter from '../../assets/filter.png';
import oops from '../../assets/oops.png';
import happy from '../../assets/happy.png';
import whatsapp from '../../assets/whatsapp.png';
import Spinner from '../Spinner';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import CarouselBanner from "./CarouselBanner";
import ReactWhatsapp from 'react-whatsapp';

@inject((store) => {
  const { myShopApiStore, ifaceMngr } = store;
  return { myShopApiStore, ifaceMngr };
})

@observer
class FastStoresScreen extends React.Component {
    constructor(props) {
        super(props)
        this.scrollUpRef = React.createRef();
        this.state = {
             modalIsOpen: false,
             isMobile: window.innerWidth <= 800
        }
    }

    updateIsMobile = () => {
        this.setState({ isMobile: window.innerWidth <= 800 });
    }

    componentDidUpdate() {
        this.scrollUpRef.current && this.scrollUpRef.current.scrollIntoView({behavior: 'smooth'})
    }
    
    componentDidMount = async () => {
        const { getShopsResp, setShopId, setShopName, shopsData } = this.props.myShopApiStore;

        // if(!shopsData){
            setShopId(null);
            setShopName(null);
            await getShopsResp();
        // }

        this.scrollUpRef.current && this.scrollUpRef.current.scrollIntoView({behavior: 'smooth'})

        window.addEventListener('resize', this.updateIsMobile)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateIsMobile);
    }

    renderShop = (shop, idx) => {
        return(
            <div key={idx}>
                <FastStore shop={shop}/>
            </div>
        )
    }

    openModal = () => {
        this.setState({modalIsOpen: true})
    }

    closeModal = () => {
        this.setState({modalIsOpen: false})
    }

 

    render() {
        const { shopsData, filteredShops, cancleFiltereds, hasFilters, shopId } = this.props.myShopApiStore;

        if(!shopsData && shopId === 1){
            return null;
        }

        const isFiltered = filteredShops.length || !hasFilters; /* >>>> added !hasFilters  (for not found shops) !!!!! */
        return (
            <div className={style.Container} ref={this.scrollUpRef}>
                <ReactWhatsapp style={{cursor: 'pointer', position: 'absolute', top: '-1000px'}} number={process.env.REACT_APP_WHATSAPP_PHONE} message={process.env.REACT_APP_WHATSAPP_MSG}>
                    <div className={style.Whatsapp} >
                        <img src={whatsapp} width="55px" alt='' />
                    </div>
                </ReactWhatsapp>
                <div className={style.TopMain}>
                    <div className={style.TopRightBtns}>
                        <div className={style.FilterBtn} onClick={this.openModal} >
                            <LazyLoadImage effect='blur' src={fast_search} width='100%' />
                        </div>
                        <div className={isFiltered ? style.CancleFilterBtn : style.DisabledCancleFilterBtn} onClick={cancleFiltereds}>
                            {
                                isFiltered 
                                ?
                                    <>
                                        <LazyLoadImage effect='blur' src={filter}/>
                                        <span>בטל סינון</span>
                                    </>
                                :
                                    null
                            }
                        </div>
                    </div>
                    <div className={style.Title}>
                        {shopsData ? shopsData.welcomeMessage : ''}
                    </div>
                    <div className={style.Spinner}>
                        <Spinner loading={!shopsData} color="#c3f5ff" loader="BeatLoader"/>
                    </div>
                </div>
                {/* <div className={style.BannerContainer}> */}
                {
                    shopsData && shopsData.banners && shopsData.banners.length &&
                            <CarouselBanner banners={shopsData.banners}/>
                }
                    
                        {/* <Carousel autoPlay={true} infiniteLoop={true} maxHeight='20px'>
                            <div>
                                <img src={happy}/>
                                <p>happy!!!!</p>
                            </div>
                            <div>
                                <img src={oops} width='10px'/>
                                <p>oops!!!!</p>
                            </div>
                            
                        </Carousel> */}
                {/* </div> */}
                <div className={style.ScreenBody}>
                    {
                        shopsData
                        ?
                            filteredShops.length
                            ?
                                <div className={style.StoreList}>
                                    
                                    { filteredShops.map(this.renderShop) }
                                    
                                </div>
                            :
                                !hasFilters
                                ?   
                                    <div className={style.Oops}>
                                        <LazyLoadImage effect='blur' src={oops} width="200px"/>
                                        <h3>לא נמצאו תוצאות...</h3>
                                    </div>
                                :
                                    <div className={style.StoreList}>
                                        { shopsData.shops.map(this.renderShop) }
                                    </div>
                        :
                            ''
                    }
                </div>
                <FilterModal modalIsOpen={this.state.modalIsOpen} closeModal={this.closeModal} />
            </div>
        )
    }
}

export default FastStoresScreen
