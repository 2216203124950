import React from "react";
import { observer, inject } from "mobx-react";
import style from "./style.module.css";

const START_COUNT = 5;

@inject((store) => {
  const { myShopApiStore, ifaceMngr } = store;
  return { myShopApiStore, ifaceMngr };
})

@observer
class CouponModal extends React.Component {
    constructor(props) {
        super(props)
        // this.isMountedRef = React.createRef();
        this.state = {
             isValidCode: true,
             code: '',
             isButtonDisabled: false,
             count: START_COUNT,
             interval: null,
        }
    }

    handleChange = e => {
        this.setState({code: e.target.value});
    }

    timer = () => {
        this.setState({count: this.state.count - 1});
    }

    activateCoupon = async () => {
        const { shop, checkCouponResp } = this.props.myShopApiStore;
        if(this.state.code.trim() === ''){
            this.setState({isValidCode: false})
        }
        else if(shop.withCoupons){
            const interval = setInterval(this.timer, 1000);
            this.setState({isButtonDisabled: true, interval})
            
            setTimeout(() => {
                clearInterval(this.state.interval);
                this.setState({isButtonDisabled: false, count: START_COUNT, interval: null})
            }
            , START_COUNT * 1000)

            const resp = await checkCouponResp(this.state.code);
            const { errorCode } = resp;
            // console.log("ERROR_CODE::",errorCode)
            if(errorCode !== '0'){
                this.setState({isValidCode: false})
            }
            else{
                const { calcTotalCartPrice } = this.props.myShopApiStore;
                calcTotalCartPrice(); //calculate new total cart price (after discount)

                clearInterval(this.state.interval);
                this.setState({isValidCode: false})
                this.props.closeModal(true);
            }
        }
    } 
    
    render() {
        const { mainColor } = this.props.myShopApiStore;
        return (
            <div className={style.Container}>
                    <div className={style.CouponTitle}>הזן כאן את קוד הקופון שלך:</div>
                    <div className={style.CouponInput}>
                        <input type="text" onChange={this.handleChange}/>
                    </div>
                    {
                        !this.state.isValidCode &&
                            <div className={style.ErrorMsg}>
                                <span>קוד קופון לא תקין</span>
                            </div>
                    }
                    <div className={style.CouponBtns}>
                        <button className={style.NoCoupon} onClick={() => this.props.closeModal(false)}>אין לי קופון</button>
                        <button className={style.CouponOn} onClick={this.activateCoupon} style={{backgroundColor: this.state.isButtonDisabled ? 'rgb(255, 255, 255, 0.07)' : mainColor, border: this.state.isButtonDisabled ? '1px solid #a5a5a5' : 'unset'}} disabled={this.state.isButtonDisabled}>הפעלת קופון</button>
                    </div>
                    <div className={style.TimeBar}>
                        {this.state.isButtonDisabled ? <span>{this.state.count}</span> : ''}
                    </div>
          
            </div>
        )
    }
}

export default CouponModal
