import React from "react";
import { observer, inject } from "mobx-react";
import style from "./style.module.css";
import pin from '../../../assets/pin.png'
import red_info from '../../../assets/red_info.svg'
import InfoModal from "./InfoModal";

@inject((store) => {
  const { myShopApiStore, ifaceMngr } = store;
  return { myShopApiStore, ifaceMngr };
})

@observer
class FastStore extends React.Component {
    constructor(props) {
        super(props)
    
        this.state = {
             modalIsOpen: false
        }
    }

    closeModal = () => {
        this.setState({modalIsOpen: false})
    }
    render() {
        const { shop } = this.props;
        const { openStore } = this.props.myShopApiStore;
        
        return (
            <div className={style.Container}>
                <div className={style.modal}>
                    <InfoModal modalIsOpen={this.state.modalIsOpen} closeModal={this.closeModal} shop={shop}/>
                </div>
                {
                    shop.description
                    ?
                        <div className={style.info} onClick={() => this.setState({modalIsOpen: true})}> 
                            <img src={red_info} width="20px"/>
                        </div>
                    :
                        null
                }
                <div className={style.ShopImage}> 
                    <img src={shop.image} onClick={() => {openStore(shop.id, shop.name)}}/>
                </div>                
                <div className={style.NameAndAddress} onClick={() => {openStore(shop.id, shop.name)}}>
                    <div className={style.ShopName}> 
                        <span>{shop.name}</span>
                    </div>                
                    <div className={style.ShopAddress}>
                        <div className={style.ShopIconAddress}>
                            <img src={pin} />
                        </div>
                        <div className={style.ShopTextAddress}>
                            <span>{shop.address}</span>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default FastStore
