import React from "react";
import { observer, inject } from "mobx-react";
import style from "./style.module.css";
import pin from '../../../assets/toskana_images/location_icon.png';
import toskana_logo from '../../../assets/toskana_images/toskana_logo.png';
import bg_store from '../../../assets/toskana_images/bg_store.png';

@inject((store) => {
  const { myShopApiStore, ifaceMngr } = store;
  return { myShopApiStore, ifaceMngr };
})

@observer
class ToskanaStore extends React.Component {
    render() {
        const { shop } = this.props;
        const { openStore } = this.props.myShopApiStore;
        
        return (
            <div className={style.Container} onClick={() => {openStore(shop.id, shop.name)}}>
                <div className={style.Wrapper}>
                    <img src={bg_store} className={style.BgStore} />
                    <div className={style.NameAndAddress}>
                        <div className={style.ShopName}> 
                            <span>{shop.name}</span>
                        </div>                
                        <div className={style.ShopAddress}>
                            <div className={style.ShopIconAddress}>
                                <img src={pin} />
                            </div>
                            <div className={style.ShopTextAddress}>
                                <span>{shop.address}</span>
                            </div>
                        </div>
                    </div>
                    <div className={style.ShopImage}> 
                        <img src={toskana_logo}/>
                    </div>     
                </div>
            </div>
        )
    }
}

export default ToskanaStore
