import React from "react";
import { observer, inject } from "mobx-react";
import style from "./style.module.css";
import CreditErrorModal from "./CreditErrorModal";
import LoadErrorModal from "./LoadErrorModal";
import ThanksModal from "../../ThanksModal";
import APILoader from "../../APILoader";
import OrderTimeModal from "../OrderTimeModal";
import ValidSMSModal from "../../ValidSMSModal";

import { BrowserRouter, Route, Switch, Link } from "react-router-dom";
import CreditIframe from "./CreditIframe";

import ReactPixel from 'react-facebook-pixel';

@inject((store) => {
  const { myShopApiStore, ifaceMngr } = store;
  return { myShopApiStore, ifaceMngr };
})
@observer
class CreditDetailsForm extends React.Component {
  constructor(props) {
    super(props);
    this.iframeRef = React.createRef();
    this.parentRef = React.createRef();
    this.months = [
      { 1: "01" },
      { 2: "02" },
      { 3: "03" },
      { 4: "04" },
      { 5: "05" },
      { 6: "06" },
      { 7: "07" },
      { 8: "08" },
      { 9: "09" },
      { 10: "10" },
      { 11: "11" },
      { 12: "12" },
    ];
    this.currYear = new Date().getFullYear();
    this.years = Array.from(Array(15), (_, x) => x + this.currYear);
    this.state = {
      errorModalIsOpen: false,
      thanksModalIsOpen: false,
      apiLoaderIsOpen: false,
      orderTimeModalIsOpen: false,
      smsModalIsOpen: false,
      sessionUrl: "",
      iframeIsOpen: true,
      errors: [],
      // id: '',
      // cc_number: '', //Credit card number
      // year: '',
      // month: '',
      // cvv: '',
      // track2: ''
    };
  }

  // componentDidMount = async() => {
  //     console.log('----------------------------componentDidMount----------------------')
  //     const { createSessionResp } = this.props.myShopApiStore;
  //     const sessionResp =  await createSessionResp();
  //     if(!sessionResp.HasError){
  //         // window.open(sessionResp.Data.SessionUrl)
  //         this.setState({sessionUrl: sessionResp.Data.SessionUrl})
  //     }
  // }

  handleCheckPreorderClick = async () => {
    const { checkPreorderResp } = this.props.myShopApiStore;
    await checkPreorderResp();
  }


  
  componentWillUnmount() {
    this.props.myShopApiStore.setDeliveryCost(0);
    this.props.myShopApiStore.calcTotalCartPrice();
  }

  errorConditions = () => {
    const { creditParams } = this.props.myShopApiStore;
    let errors = [];
    if (creditParams.id.trim() === "") {
      errors.push("id");
    }
    if (creditParams.cc_number.trim() === "") {
      errors.push("cc_number");
    }
    if (creditParams.cvv.trim() === "") {
      errors.push("cvv");
    }
    if (creditParams.year.trim() === "" || creditParams.year.trim() === "שנה") {
      errors.push("year");
    }
    if (
      creditParams.month.trim() === "" ||
      creditParams.month.trim() === "חודש"
    ) {
      errors.push("month");
    }

    return errors;
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { registerUser, createSessionResp, makePreorderResp } = this.props.myShopApiStore;

    const errors = this.errorConditions();

    if (!errors.length) {
      this.openAPILoader();
      const regResp = await registerUser();

      if (regResp.data) {
        //valid user token
        // await this.handleCreditPaymentCall();

        // const preResp = await makePreorderResp();
        // console.log('preResp:',preResp)

        const sessionResp = await createSessionResp();
        if (!sessionResp.HasError) {
          // window.open(sessionResp.Data.SessionUrl)
          this.setState({ sessionUrl: sessionResp.Data.SessionUrl });
        }

      } else {
        //invalid user token
        this.openSmsModal();
      }

      this.closeAPILoader();
    } else {
      this.setState({
        errors,
      });
    }
  };

  // handleMakeOrderCall = async () => {

  // }

  handleCreditPaymentCall = async () => {
    const {
      makeOrderResp,
      validateCardResp,
      shop,
      commitFullTransResp,
      creditParams,
      setCreditParams,
    } = this.props.myShopApiStore;
    const { paymentEndpoint, paymentKey, directPayment } = shop;
    const expDate_MMYY = this.getFormattedExpDate();

    this.openAPILoader();
    const { token, errorMsg } = await validateCardResp(
      paymentEndpoint,
      paymentKey,
      creditParams.track2,
      creditParams.cc_number,
      expDate_MMYY
    );
    this.closeAPILoader();

    if (errorMsg) {
      this.setState({ errorModalIsOpen: true });
      return;
    }

    if (directPayment === 1) {
      if (token) {
        this.openAPILoader();
        const resp = await makeOrderResp(null, token, {
          ...creditParams,
          expDate: expDate_MMYY,
        });
        this.closeAPILoader();

        if (resp.data.errorCode !== "0") {
          if (resp.data.errorCode === "DATE_ERROR") {
            this.openOrderTimeModal();
          } else {
            // console.log("ERROR_CODE:", resp.data.errorCode);
            this.openErrorModal();
          }
        } else {
          setCreditParams("token", token); //save token for fast next credit payment!
          setCreditParams("cvv", ""); // reset cvv

          if(process.env.REACT_APP_FACEBOOK_PIXEL != '') ReactPixel.track('Purchase');

          // finish with openning thanks modal
          this.openThanksModal();
        }
      }
    } else {
      this.openAPILoader();
      const { referenceNumber, errorMsg } = await commitFullTransResp(
        token,
        paymentEndpoint,
        paymentKey,
        creditParams.track2,
        creditParams.id,
        creditParams.cc_number,
        creditParams.cvv,
        expDate_MMYY
      );
      this.closeAPILoader();
      if (errorMsg) {
        this.setState({ errorModalIsOpen: true, apiLoaderIsOpen: false });
        return;
      }

      if (referenceNumber && token) {
        /// CHECK THAT!!!
        this.openAPILoader();
        const resp = await makeOrderResp(referenceNumber, token, {
          ...creditParams,
          expDate: expDate_MMYY,
        });
        this.closeAPILoader();

        if (resp.data.errorCode !== "0") {
          if (resp.data.errorCode === "DATE_ERROR") {
            this.openOrderTimeModal();
          } else {
            // console.log("ERROR_CODE:", resp.data.errorCode);
            this.openErrorModal();
          }
        } else {
          setCreditParams("token", token); //save token for fast next credit payment!
          setCreditParams("cvv", ""); // reset cvv

          if(process.env.REACT_APP_FACEBOOK_PIXEL != '') ReactPixel.track('Purchase');

          // finish with openning thanks modal
          this.openThanksModal();
        }
      }
    }
  };
  handleIframeCreditPaymentCall = async () => {
    const { makeOrderResp } = this.props.myShopApiStore;
    this.openAPILoader();
    const resp = await makeOrderResp();
    if (resp?.data?.errorCode === "0") {
      // finish with openning thanks modal
      if(process.env.REACT_APP_FACEBOOK_PIXEL != '') ReactPixel.track('Purchase');

      this.openThanksModal();
    }
    else{
      this.openOrderTimeModal();
      
    }
    this.closeAPILoader();
  };

  getFormattedExpDate = () => {
    // MMYY Format
    const { creditParams } = this.props.myShopApiStore;
    const y = creditParams.year; // YYYY
    const m = creditParams.month; // MM

    let formated = m + (y % 100); //MMYY (string)
    return formated;
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    const { setCreditParams } = this.props.myShopApiStore;

    if (name === "cvv" || name === "cc_number" || name === "id") {
      !value.match(/\D/) && setCreditParams(name, value);
    } else {
      setCreditParams(name, value);
    }

    let errors = this.state.errors;
    const eIndex = errors.indexOf(name);
    if (eIndex > -1) {
      errors.splice(eIndex, 1);
      this.setState({
        errors: errors,
      });
    }
  };

  closeOrderTimeModal = () => {
    this.setState({ orderTimeModalIsOpen: false });
  };

  openOrderTimeModal = () => {
    this.setState({ orderTimeModalIsOpen: true });
  };

  openAPILoader = () => {
    this.setState({ apiLoaderIsOpen: true });
  };

  closeAPILoader = () => {
    this.setState({ apiLoaderIsOpen: false });
  };

  openErrorModal = () => {
    this.setState({ errorModalIsOpen: true });
  };

  closeLoadErrorModal = () => {
    const { setSessionUrl } = this.props.myShopApiStore;
    setSessionUrl('');
    this.setState({sessionUrl: ''})
    this.props.prevStep();
  };

  closeErrorModal = () => {
    this.setState({ errorModalIsOpen: false });
  };

  openThanksModal = () => {
    this.setState({ thanksModalIsOpen: true });
  };

  closeThanksModal = () => {
    this.setState({ thanksModalIsOpen: false });
  };

  openSmsModal = () => {
    this.setState({ smsModalIsOpen: true });
  };

  closeSmsModal = () => {
    this.setState({ smsModalIsOpen: false });
  };


//   closeIframe = () => {
//     let url =
//       window.location != window.parent.location
//         ? document.referrer
//         : document.location.href;
//     window.parent.postMessage("close-iframe", url);
//   };


  componentDidUpdate(prevProps, prevState) {
      // console.log("iframeRef:", this.iframeRef)
  }

  closeIframe = () => {
      if(this.iframeRef.current){
          // this.iframeRef.current.hidden ? this.iframeRef.current.hidden = false : this.iframeRef.current.hidden = true;
          this.iframeRef.current.contentWindow.close();
      }
  }
  
  componentDidMount = () => {
    if(this.state.iframeIsOpen){
      window.onmessage = e => {
        if (e.data === 'close-iframe') {
          this.setState({iframeIsOpen: false})
          this.handleIframeCreditPaymentCall();
          // alert('SUCCESS ! ! !');
        }
      }
    }
  }

  handleBackBtn = () => {
    const { setSessionUrl } = this.props.myShopApiStore;
    setSessionUrl('');
    this.setState({sessionUrl: ''})
    this.props.prevStep();
  }

  render() {
    const {
      creditParams,
      resetCreditParams,
      orderData,
      mainColor,
      sessionUrl,
    } = this.props.myShopApiStore;

    if (this.props.currentStep !== 3) {
      return null;
    } else {
      this.props.myShopApiStore.calcTotalCartPrice();
    }

    return (
      <div className={style.Container} ref={this.parentRef}>
        <div
          className={style.CreditPayTitle}
          style={{ backgroundColor: mainColor}}
        >
          תשלום באשראי
        </div>
        {/* <button onClick={this.handleCheckPreorderClick}>check preorder</button> */}
        {/* <button onClick={this.closeIframe}>close iframe</button> */}
        {
          this.state.iframeIsOpen && sessionUrl && sessionUrl != "ERROR"
          ?
            <>
              <CreditIframe iframeRef={this.iframeRef} parentRef={this.parentRef} />
              <button type="button" 
                      style={{backgroundColor: 'unset', border: '2px solid rgb(255, 255, 255, 0.3)'}}
                      onClick={this.handleBackBtn}>חזור</button>
            </>
          :
            null
        }
        <LoadErrorModal
          closeModal={this.closeLoadErrorModal}
          modalIsOpen={sessionUrl == "ERROR"}
        />
        <CreditErrorModal
          closeModal={this.closeErrorModal}
          modalIsOpen={this.state.errorModalIsOpen}
        />
        <ThanksModal
          closeModal={this.closeThanksModal}
          modalIsOpen={this.state.thanksModalIsOpen}
        />
        <APILoader modalIsOpen={this.state.apiLoaderIsOpen} />
        <OrderTimeModal
          closeModal={this.closeOrderTimeModal}
          modalIsOpen={this.state.orderTimeModalIsOpen}
          orderType={orderData.isDelivery ? "משלוחים" : "באיסוף עצמי"}
        />
        <ValidSMSModal
          modalIsOpen={this.state.smsModalIsOpen}
          closeModal={this.closeSmsModal}
          handleCreditPaymentCall={this.handleCreditPaymentCall}
          action="Delivery"
          nextStep={this.props.nextStep}
        />
      </div>
    );
  }
}

export default CreditDetailsForm;
