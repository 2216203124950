import React from "react";
import { observer, inject } from "mobx-react";
import style from "./style.module.css";
import Modal from "react-modal";

@inject((store) => {
  const { myShopApiStore, ifaceMngr } = store;
  return { myShopApiStore, ifaceMngr };
})

@observer
class InfoModal extends React.Component {
    render() {
        const { shop } = this.props;
        const { openStore } = this.props.myShopApiStore;
        
        return (
            <Modal
                onRequestClose={this.props.closeModal}
                isOpen={this.props.modalIsOpen} 
                className={style.container}
                style={
                    {
                        overlay: {
                            backgroundColor: 'rgba(0,0,0,.6)'
                        },
                        content: {
                            top: '50%',
                            left: '50%',
                            right: 'auto',
                            bottom: 'auto',
                            marginRight: '-50%',
                            transform: 'translate(-50%, -50%)',
                            margin: '0 auto',
                            padding: 0,
                            maxHeight: '70%',
                            maxWidth: '100%',
                            backgroundColor: '#fff',
                            borderRadius: '15px',
                            width: '700px',
                            height: '400px'
                        }
                    }
                }>
                <div className={style.Container}>
                    <div className={style.cancleBtn} onClick={this.props.closeModal}>✖</div>
                    <img src={shop.image} width='30%' />
                    <div className={style.Content}>
                        {shop.description}
                    </div>
                </div>
            </Modal>
        )
    }
}

export default InfoModal
