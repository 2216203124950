import React from 'react';
import { observer, inject } from 'mobx-react';
// import ReactDOM from 'react-dom';
import style from './style.module.css';

@inject(store => {
    const { myShopApiStore } = store;
    return { myShopApiStore };
})

@observer
class WorkTime extends React.Component {
    render() {
        const dayofw = {
            sunday: "יום א'",
            monday: "יום ב'",
            tuesday: "יום ג'",
            wednesday: "יום ד'",
            thursday: "יום ה'",
            friday: "יום ו'",
            saturday: "יום ש'",
        }

        return (
            <div className={style.container}>
                <div className={style.TopDiv}>
                    {
                        this.props.items.map((item, idx) => {
                            return (
                                <div className={style.day} key={`time_${idx}`}>
                                    <div className={style.dayName}>{dayofw[item.weekday]}</div>
                                    <div className={style.dayHours}>{item.from}-{item.to}</div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        )
    }
}

export default WorkTime;