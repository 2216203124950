import React from 'react';
import { observer, inject } from 'mobx-react';
// import ReactDOM from 'react-dom';
import NumberFormat from 'react-number-format';
import style from './style.module.css';
import CarouselModal from 'Components/MainScreen/AppBody/ShopMenu/ItemList/Item/CarouselModal';

@inject(store => {
    const { myShopApiStore } = store;
    return { myShopApiStore };
})

@observer
class Item extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            carouselModalIsOpen: false
        }
        this.curr = '₪'
    }

    closeCarouselModal = () => {
        this.setState({carouselModalIsOpen: false})
    }

    openCarouselModal = () => {
        this.setState({carouselModalIsOpen: true})
    }

    getFormatedCost = cost => {
        return <NumberFormat value={cost} prefix={this.curr} decimalScale={2} fixedDecimalScale={true} displayType={'text'} />
    }

    getFormatedUnitType = unitType => {
        return unitType === 'unit' ? "יח'" : "קילו"
    }

    render() {
        const { mainColor } = this.props.myShopApiStore;
        const { item } = this.props;

        const imageBig = item.shopProduct ? item.shopProduct.product.imageBig : null;
        return (
            <div className={style.Container}>
                <div className={style.modals}>
                    {
                        imageBig && typeof imageBig !== 'string' && imageBig.length && <CarouselModal modalIsOpen={this.state.carouselModalIsOpen} closeModal={this.closeCarouselModal} item={item.shopProduct}/>
                    }
                </div>
                <div className={style.RightDiv}>
                    <div className={style.Image}><img src={item.shopProduct.product.image} alt='' /></div>
                    {/* <div className={style.Tag}>טאגג</div> */}
                </div>
                <div className={style.CenterDiv}>
                    <div className={style.Name} style={{color: mainColor}}>{item.shopProduct.product.name}</div>
                    <div className={style.CenterBottomDiv}>
                        <div className={style.ItemCost}>{this.getFormatedCost(item.price)} ל{this.getFormatedUnitType(item.unitType)}</div>
                        {
                            item.comment
                            ?
                                <div className={style.Comment}>{`הערה: ${item.comment}`}</div>
                            :
                                null
                        }
                    </div>
                </div>
                <div className={style.LeftDiv}>
                    <div className={style.Amount}>{item.amount} {this.getFormatedUnitType(item.unitType)}</div>
                    <div className={style.TotalCost}>{this.getFormatedCost(item.total)}</div>
                </div>
            </div>
        )
    }
}

export default Item;