import React from 'react';
import { observer, inject } from 'mobx-react';
import style from './style.module.css';
import Modal from 'react-modal';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

@inject(store => {
    const { myShopApiStore, ifaceMngr } = store;
    return { myShopApiStore, ifaceMngr };
})

@observer
class ProductsAssociatedModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: window.innerWidth <= 600
        }
        this.modalStyle = {
            content : {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                borderRadius: '20px',
                // width: window.innerWidth <= '850px' ? '90%' : '40%',
                height: '80%'
                
            },
            overlay: {
                backgroundColor: "rgb(0, 0, 0, 0.65)",
                zIndex: 10
            }
        }
    }

    handleCloseClick = () => {
        const {setAssociatedStage} = this.props.myShopApiStore;
        setAssociatedStage(0);
        this.props.closeModal();
    }
    
    getShekelsFormat = (num) => {
        return Math.floor(num).toString();
    }
    getAgorotFormat = (num) => {
        const numFixed2 = (Math.floor(num)- num).toFixed(2);
        const l = numFixed2.toString().length;
        const agorot = numFixed2[l-2] + numFixed2[l-1];
        return agorot;
    }

    componentDidMount = () => {
        this.setState({isMobile: window.innerWidth <= 600})
    }
    

    render() {
        const { modalIsOpen, closeModal, itemList, handleAddClick } = this.props;
        const { shop, mainColor, shopData } = this.props.myShopApiStore;

        return (
            <Modal style={this.modalStyle} isOpen={modalIsOpen} onRequestClose={this.handleCloseClick}>
                <div className={style.ModalContainer}>
                    <div className={style.Header}>
                        {shopData.associatedDescription}
                    </div>
                    <div className={style.CartProducts}>
                    {
                        itemList?.map((item, index) => {
                            const shekels = item?.unitTypes?.length ? item.unitTypes[0].price : ''
                            const hasProductOptions = (item && item.shopProductOptions) ? item.shopProductOptions.length > 0 : false;
                            const hasToppings = (item && item.shopToppings) ? item.shopToppings.length > 0 : false;
                            const hasBaseToppings = (item && item.shopBaseToppings) ? item.shopBaseToppings.length > 0 : false;
                            const isEditableItem = hasProductOptions || hasToppings || hasBaseToppings;
                            
                            return(
                                <div className={style.ItemContainer} key={index}>
                                    <div onClick={() => {handleAddClick(item, isEditableItem);} }>
                                        <div className={style.ItemImage}>
                                            <LazyLoadImage effect='blur' 
                                                width="100%"
                                                src={item.product.image}
                                            />
                                            <div className={style.PriceCircle} style={{backgroundColor: mainColor}}>
                                                <div className={style.Agorot}>
                                                    {this.getAgorotFormat(shekels)}
                                                </div>
                                                <div className={style.Shekels}>
                                                    {this.getShekelsFormat(shekels)}
                                                </div>
                                                <div className={style.Curr}>
                                                    ₪
                                                </div>
                                            </div>
                                        </div>
                                        <div className={style.ItemName} style={{color: mainColor}}>{item?.product?.name}</div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    </div>
                    <div className={style.NoThanksFooter}>
                        <span onClick={closeModal}>המשך לתשלום</span>
                    </div>
                </div>
            </Modal>
        )
    }
}

export default ProductsAssociatedModal;