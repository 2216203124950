import React from 'react';
import { observer, inject } from 'mobx-react';
// import ReactDOM from 'react-dom';
import style from './style.module.css';
import WorkTime from './WorkTime';

@inject(store => {
    const { myShopApiStore } = store;
    return { myShopApiStore };
})


@observer
class Contacts extends React.Component {
    render() {
        return (
            <div className={style.container}>
                <div className={style.Header}>יצירת קשר</div>
                <div className={style.Contacts}>
                    <div className={style.ContactsName} style={{ color: this.props.myShopApiStore.mainColor }}>{this.props.myShopApiStore.getShopName()} - {this.props.myShopApiStore.getShopAddress()}</div>
                    <div className={style.ContactsPhone}>מספר טלפון: <a href={`tel:${this.props.myShopApiStore.getShopPhone2()}`}>{this.props.myShopApiStore.getShopPhone2()}</a></div>
                    {
                        this.props.myShopApiStore.getShopWorkTimes()? 
                            <WorkTime items={this.props.myShopApiStore.getShopWorkTimes()}/>
                        : ''
                    }
                    <div className={style.ContactsDesc}>לכל שינוי או ביטול יש ליצור איתנו קשר</div>
                    {/* <div className={style.ContactsEmail}><a href={`mailto:${this.props.myShopApiStore.getShopContactEmail()}`}>{this.props.myShopApiStore.getShopContactEmail()}</a></div> */}
                </div>
            </div>
        )
    }
}

export default Contacts;
