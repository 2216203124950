import React from 'react';
import { observer, inject } from 'mobx-react';
// import ReactDOM from 'react-dom';
import searchIcon from 'assets/search.png';
import style from './style.module.css';

@inject(store => {
    const { ifaceMngr, myShopApiStore } = store;
    return { ifaceMngr, myShopApiStore };
})

@observer
class SearchField extends React.Component {
    constructor(props) {
        super(props)
        this.inputRef = React.createRef();
        /*this.state = {
            searchFieldIsOpen: false
        }*/
    }
    
    // openSearchField = () => {
    //     !this.state.searchFieldIsOpen && this.setState({searchFieldIsOpen: true})
    // }

    /*toggleSearchField = () => {
        //this.state.searchFieldIsOpen ? this.setState({searchFieldIsOpen: false}) : this.setState({searchFieldIsOpen: true})
        this.setState({searchFieldIsOpen: true})
    }*/

    componentDidUpdate() {
        this.props.myShopApiStore.searchFieldIsOpen && this.inputRef.current && this.inputRef.current.focus()
    }

    searchItems = async text => {
        const { getSearchProducts, setSuggestions, setSearchValue } = this.props.myShopApiStore;
        const { setActiveTopMenu } = this.props.ifaceMngr;
        setSearchValue(text);
        if(!text){
            setSuggestions([])
        }
        else{
            setActiveTopMenu('ShopMenu');

            const itemList = await getSearchProducts(text);
            let matches = itemList.filter(item => {
                const regex = new RegExp(text.toLowerCase(), 'gi');
                return item.product.name.toLowerCase().match(regex);
            })
    
            this.setState({suggestions: matches});
            setSuggestions(matches);
        }
    }
    
    render() {
        const { searchValue, searchFieldIsOpen, toggleSearchField } = this.props.myShopApiStore;
        
        return(
            <div className={style.Container}>
                <div className={style.InputField} onClick={() => toggleSearchField(true)}
                        style={{width: searchFieldIsOpen ? '130px' : '30px'}}>
                    <img src={searchIcon} alt='' width='16px' />
                    {
                        searchFieldIsOpen &&
                            <input ref={this.inputRef} type='text' style={{backgroundColor: 'unset', color: '#FFF', outline: 'none',
                                marginRight: '7px', border: 'none', fontSize: '12px', width: '150px', overflow: 'auto'}}
                                value={searchValue} placeholder='חפש מוצר...' onChange={e => this.searchItems(e.target.value)}/>            
                    }
                </div>
            </div>
        )
    }
}

export default SearchField;
