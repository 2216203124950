import React, { Component } from 'react'
import './CarouselBanner.css';
import img1 from '../../../assets/bg/bg_4.jpg';
import img2 from '../../../assets/bg/bg_5.jpg';
import img3 from '../../../assets/bg/bg_6.jpg';

class CarouselBanner extends Component {
    constructor(props) {
        super(props)
        this.isNextSlideReadyRef = React.createRef(false);
        this.state = {
            sliderElement: null,
            x: 0,
            transition: 'transform 1s ease-out',
        }
        this.SLIDER_SIZE = this.props.banners.length;
    }
    
    componentDidMount() {
        this.setState({sliderElement: document.querySelector('.slider'),})
        if(this.isNextSlideReadyRef){
            this.isNextSlideReadyRef.current = true;
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if(prevState.x === this.state.x && this.isNextSlideReadyRef.current){
            this.isNextSlideReadyRef.current = false;
            setTimeout(this.handleNext, 3000);

        }
    }

    handleNext = () => {
         this.setState({x: this.state.x+(100/this.SLIDER_SIZE)})
         this.isNextSlideReadyRef.current = true;

    }
    handlePrev = () => {
        this.setState({x: this.state.x-(100/this.SLIDER_SIZE)})
    }

    handleTransEnd = (e) => {
        this.state.sliderElement.appendChild(this.state.sliderElement.firstChild);
        
        this.setState({x: 0, transition: 'none'})
        setTimeout(() => {
            this.setState({transition: 'transform 1s ease-out'})
        })
    }

    sliderStyle = () => {
        const styles = {
            width: `${100*this.SLIDER_SIZE}%`,
            transform: `translate(-${this.state.x}%)`,
            transition: this.state.transition
        }
        return styles

    }
    render() {
        const { banners } = this.props;
        return (
            <div className='slider-container'>
                <div className='slider' style={this.sliderStyle()} onTransitionEnd={this.handleTransEnd}>
                {
                    banners
                    ?
                        banners.map((banner,idx) => {
                            return(
                                <div key={`${banner.id}_${idx}`} className='slide' style={{flex: `0 1 ${100/this.SLIDER_SIZE}%`}} onClick={() => window.open(banner.link)}>
                                    {/* <div className='price'>
                                        <div className='shekels'>69</div>
                                        <div className='agorot'>99</div>
                                    </div> */}
                                    <img src={banner.image} alt=''/>
                                </div>
                            )
                        })
                    :
                        null
                }
                    {/* <div className='slide' style={{flex: `0 1 ${100/this.SLIDER_SIZE}%`}} onClick={() => window.open("https://www.google.com")}>
                        <div className='price'>
                            <div className='shekels'>69</div>
                            <div className='agorot'>99</div>
                        </div>
                        <img src={img1} alt='' />
                    </div>
                    <div className='slide' style={{flex: `0 1 ${100/this.SLIDER_SIZE}%`}} onClick={() => window.open("https://www.google.com")}>
                        <div className='price'>
                            <div className='shekels'>31</div>
                            <div className='agorot'>00</div>
                        </div>
                        <img src={img2} alt='' />
                    </div>
                    <div className='slide' style={{flex: `0 1 ${100/this.SLIDER_SIZE}%`}} onClick={() => window.open("https://www.google.com")}>
                        <div className='price'>
                            <div className='shekels'>9</div>
                            <div className='agorot'>50</div>
                        </div>
                        <img src={img3} alt='' />
                    </div> */}
                </div>
                {/* <div className='controls'>
                    <div className='prev' onClick={this.handlePrev}>prev</div>
                    <div className='next' onClick={this.handleNext}>next</div>
                </div> */}
            </div>
        )
    }
}

export default CarouselBanner
