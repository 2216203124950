import React from 'react';
import { observer, inject } from 'mobx-react';
// import ReactDOM from 'react-dom';
import style from './style.module.css';
import Modal from 'react-modal';
import welcome_bg from '../../../assets/welcome_bg.png'
@inject(store => {
    const { myShopApiStore, ifaceMngr } = store;
    return { myShopApiStore, ifaceMngr };
})

@observer
class WelcomeModal extends React.Component {
    constructor(props) {
        super(props);
        this.modalStyle = {
            content : {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                borderRadius: '20px',
                margin: 0,
                padding: 0,
                background: `url(${welcome_bg}) no-repeat`,
                backgroundSize: '100%',
                backgroundPosition: 'top',
                backgroundColor: '#FFFF',
                maxHeight: '100%',
                // width: '30%',
                
                width: '450px',
                height: '243px',
                
            },
            overlay: {
                backgroundColor: "rgb(0, 0, 0, 0.65)",
                zIndex: 10
            }
        }
    }

    goMenu = () => {
        const { setActiveTopMenu } = this.props.ifaceMngr;
        const { closeModal } = this.props;
        
        setActiveTopMenu("ShopMenu");
        closeModal();
    }
    render() {
        const {modalIsOpen } = this.props;
        const { shop, mainColor } = this.props.myShopApiStore;
        return (
            <Modal style={this.modalStyle} isOpen={modalIsOpen} onRequestClose={this.goMenu}>
                <div className={style.ModalContainer}>
                    <h3>
                        ברוכים הבאים ל-{shop?.name}
                    </h3>
                    <button type="text" onClick={this.goMenu} style={{color: mainColor, border: `1px solid ${mainColor}` }}>
                        מעבר לתפריט
                    </button>
                </div>
            </Modal>
        )
    }
}

export default WelcomeModal;