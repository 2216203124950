import React from 'react';
import { observer, inject } from 'mobx-react';
import style from './style.module.css';
import Modal from 'react-modal';
import Spinner from '../../../Spinner';

@inject(store => {
    const { myShopApiStore, ifaceMngr } = store;
    return { myShopApiStore, ifaceMngr };
})

@observer
class APILoader extends React.Component {
    constructor(props) {
        super(props);
        this.modalStyle = {
            content : {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                // display: 'flex',
                // justifyContent: 'center',
                // alignItems: 'center',
                width: '100%',
                height: '100%',
                backgroundColor: "rgb(255, 255, 255, 0.5)",
                
            },
            overlay: {
                backgroundColor: "rgb(255, 255, 255, 0)",
                zIndex: 200
            }
        }
    }
    
    render() {
        const { modalIsOpen } = this.props;

        return (
            <Modal style={this.modalStyle} isOpen={modalIsOpen}>
                <div className={style.ModalContainer}>
                    <div className={style.SpinnerIcon}>
                        <Spinner loading={modalIsOpen} color="#000033" loader="RingLoader"/>
                    </div>
                    <div className={style.Content}>
                        <span>המתן להשלמת הפעולה...</span>
                    </div>
                </div>
            </Modal>
        )
    }
}

export default APILoader;