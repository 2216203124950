import React from 'react';
import { observer, inject } from 'mobx-react';
// import ReactDOM from 'react-dom';
import style from './style.module.css';

import ShopMenu from './ShopMenu';
import History from './History';
import About from './About';
//import Terms from './Terms';
//import PPolicy from './PrivacyPolicy';
//import RetPolicy from './ReturnPolicy';
import Registration from './Registration';
import Contacts from './Contacts';
import Payment from './Payment';

@inject(store => {
    const { ifaceMngr, myShopApiStore } = store;
    return { ifaceMngr, myShopApiStore };
})

@observer
class AppBody extends React.Component {
    componentDidMount() {
        const { setSearchValue } = this.props.myShopApiStore;
        setSearchValue('', true);
    }

    render() {
        const { activeTopMenu } = this.props.ifaceMngr;
        const abouts = ["About", "Terms", "PPolicy", "RetPolicy"]; 
        return (
        <div className={style.container}>
            <div className={style.AppBody}>
                {
                    (activeTopMenu === "Payment") ? (
                        <Payment />
                    ) : ''
                }
                {
                    (activeTopMenu === "ShopMenu") ? (
                        <ShopMenu basketIsOpen={this.props.basketIsOpen} closeBasket={this.props.closeBasket} fastToggleBasket={this.props.fastToggleBasket} toggleBasket={this.props.toggleBasket}/>
                    ) : ''
                }
                {
                    (activeTopMenu === "History") ? (
                        <History />
                    ) : ''
                }
                {
                    (abouts.includes(activeTopMenu)) ? (
                        <About />
                    ) : ''
                }
                {
                    (activeTopMenu === "Contacts") ? (
                        <Contacts />
                    ) : ''
                }
                {
                    (activeTopMenu === "Registration") ? (
                        <Registration />
                    ) : ''
                }
            </div>
        </div>
        )

    }
}

export default AppBody;