import React from 'react';
import { observer, inject } from 'mobx-react';
import style from './style.module.css';
import Modal from 'react-modal';
import InputFields from './InputFields';

@inject(store => {
    const { myShopApiStore, ifaceMngr } = store;
    return { myShopApiStore, ifaceMngr };
})

@observer
class ValidSMSModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            code1: "", 
            code2: "", 
            code3: "", 
            code4: "",
            isMobile: window.innerWidth <= 950,
        }

        this.modalStyle = {
            content : {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                borderRadius: '20px',
                width: this.state.isMobile ? '100vw' : '30%',
                
            },
            overlay: {
                backgroundColor: "rgb(0, 0, 0, 0.65)",
                zIndex: 10
            }
        }
    }

    updateIsMobile = () => {
        this.setState({ isMobile: window.innerWidth <= 850 });
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateIsMobile); 
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateIsMobile);
      }

    handleConfirmModalBtn = async () => {
        let code, isWrongSMSCode;
        if(this.state.code1 && this.state.code2 && this.state.code3 && this.state.code4){
            code = this.state.code1 + this.state.code2 + this.state.code3 + this.state.code4
            this.setState({code1: "", code2: "", code3: "", code4: ""})
            isWrongSMSCode = await this.props.myShopApiStore.verifyPhone(code)
        }
        else{
            this.setState({code1: "", code2: "", code3: "", code4: ""})
            isWrongSMSCode = true;
            this.props.myShopApiStore.setWrongSMSCode(true);
        }
        
        if(!isWrongSMSCode){
            const { setActiveTopMenu } = this.props.ifaceMngr;
            const { orderData } = this.props.myShopApiStore;

            this.props.closeModal();
            
            if(this.props.action  === 'Registration'){
                setActiveTopMenu("ShopMenu");
            }
            ////--->>>> // makes delivery order!
            else if(this.props.action === 'Delivery'){
                this.props.openMapModal();
            }
            ////--->>>>
        }
    }

    handleCodeChange = (e) => {
        const { name, value } = e.target;

        if(!value.match(/\D/)){
            this.setState({[name]: value.slice(-1)});
        }
    }

    handleNewCodeClick = async () => {
        const { setWrongSMSCode, registerUser } = this.props.myShopApiStore;
        await registerUser();
        this.setState({code1: "", code2: "", code3: "", code4: ""});
        setWrongSMSCode(false);
    }

    render() {
        const { modalIsOpen, closeModal } = this.props;
        const { isWrongSMSCode, mainColor} = this.props.myShopApiStore;
        return (
            <Modal style={this.modalStyle} isOpen={modalIsOpen}>
                <div className={style.ModalContainer}>
                    <form className={style.InputsForm} onSubmit={e => e.preventDefault()}>
                        <div className={style.ModalTitle}>
                            שלחנו לך קוד אימות לנייד
                            <br/>
                            אנא הזן אותו כאן
                            {isWrongSMSCode && 
                                <div className={style.WrongCodeText}>
                                    קוד שגוי
                                </div>
                            }
                        </div>
                        <div className={style.ModalInputs}>
                            <div className={style.Inputs}>
                                <InputFields isMobile={this.state.isMobile} handleChange={this.handleCodeChange} c1={this.state.code1} c2={this.state.code2} c3={this.state.code3} c4={this.state.code4}/>
                            </div>
                            
                            <div className={style.NewCodeText}>
                            {isWrongSMSCode &&
                                <>
                                    <span>לקבלת קוד חדש </span>
                                    <span onClick={this.handleNewCodeClick}>לחץ כאן</span>
                                </>
                            }
                            </div>
                        </div>
                        <div className={style.ModalBtns}>
                            <button type='button' onClick={closeModal}>ביטול</button>
                            <button type='submit' onClick={this.handleConfirmModalBtn} style={{color: mainColor, border: `1px solid ${mainColor}`}}>
                                אישור
                            </button>
                        </div>
                    </form>
                </div>
            </Modal>
        )
    }
}

export default ValidSMSModal;